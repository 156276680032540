body {
  overflow-x: hidden;
  
}

*{
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}