.List {
    border: 0px solid #d9dddd;
    scrollbar-width: thin;
    
}


/* Works on Chrome, Edge, and Safari */
.List::-webkit-scrollbar {
    width: 4px;
  }
  
.List::-webkit-scrollbar-track {
    background:"white";
  }
  
.List::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 20px;
    border: 0px solid orange;
  }

